
import { FaFacebook, FaTwitter, FaInstagram, } from 'react-icons/fa'
import {FiGlobe} from 'react-icons/fi';
 
function Footer() {
	return (
		<footer className="w-full bg-gray-100 dark:bg-gray-900 py-16">
		<div className="md:px-12 lg:px-28">
		  <div className="container m-auto space-y-6 text-gray-600 dark:text-gray-300">
			<img src="/image/adastra@4x.png" alt="logo adastra" className="m-auto w-40" />
			<ul
			  role="list"
			  className="flex flex-col items-center justify-center gap-4 py-4 sm:flex-row sm:gap-8"
			>
			  <li role="listitem"><a href="/" className="hover:text-primary">Ana sayfa</a></li>
			  <li role="listitem"><a href="/hakkimizda" className="hover:text-primary">Hakkımızda</a></li>
			  <li role="listitem"><a href="/iletisim" className="hover:text-primary">İletişim</a></li> 
			</ul>
			<div className="m-auto flex w-max items-center justify-between space-x-4">
			  <a href="tel:+243996660436" aria-label="call">
				 
			  </a>
			  <a href="mailto:hello@mail.com" aria-label="send mail">
				 
			  </a>
			  <a href="#" title="facebook" target="blank" aria-label="facebook">
				 
			  </a>
			  <a href="#" title="linkedin" target="blank" aria-label="linkedin">
				 
			  </a>
			</div>
	  
			<div className="text-center">
			  <span className="text-sm tracking-wide"
				>Copyright © adastrai.com <span id="year"></span> | All right reserved</span>
		 
			</div>
		  </div>
		</div>
	  </footer>
	)
}

export default Footer