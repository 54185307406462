import { Suspense } from "react";
 
import  './i18n'
import 'preline'

import AIConsulting from 'components/ai/AIConsulting'
import BlogDetail from 'components/ai/BlogDetail'

import Training from 'components/ai/Training'
import Blog from 'components/ai/Blog'
import AINews from 'components/ai/AINews'
import Header from 'components/Header'

import MobileApp  from 'components/MobileApp';
import HeroSection from 'components/HeroSection';
 
import Footer from 'components/Footer'; 
import FlyNavigation from 'components/FlyNavigation';
import About from 'components/About';
 
import Contacts from 'components/Contacts';

import { BrowserRouter, HashRouter,Route, Routes  } from 'react-router-dom';
import React, { useEffect, useState } from "react";
 
 
import AOS from 'aos';
import 'aos/dist/aos.css';
import Development from './components/services/Development';
import Website from './components/services/Website'; 
import FlyNavigation2 from './components/FlyNavigation2'; 

/**
 * For using the same JSX element with **multiple** paths
 * @param {JSXElement} el - JSX element
 * @param  {...string} paths - all paths that point to given JSX element
 * @returns `Route` elements
 */
function MultiRoute (el, ...paths) {
	return paths.map((p) => <Route key={p} element={el} path={p} />);
  };

function App() {
 
 
	useEffect(() => {
        AOS.init(); 
      }, [])
	return (
		
		<>
		 <FlyNavigation2/>
		  <HashRouter>

		  	<div  data-aos="fade-up"data-aos-duration="500">
			 
			
				<Routes>
					<Route path='/' element={
						<>
			
						<HeroSection /> </>
					} />
						<Route path='/blogdetail' element={

						<BlogDetail />
						} />
					<Route path='/yapay-zeka-danismanlik' element={
				 
						<AIConsulting /> 
					
						} />
					<Route path='/egitim-atolye' element={
	
								<Training />

					} />
					
		 
 					 
					<Route path='/web-site-gelistirme' element={
				
								<Website />
					
						
					} />

					<Route path='/yazilim-gelistirme' element={
						 
								<Development />
				 
						
					} />
					<Route path='/veri-mimarisi-gelistirme' element={
							<MobileApp />
					} />
						{MultiRoute(<About />, '/about', '/hakkimizda' )}
				 
					 
			 
					<Route path='/iletisim' element={
							<Contacts />
					} />
				</Routes>
				
			</div>
			</HashRouter>
		 

			<Footer />
		 

		</>
	);
}

export default App;
