import {useEffect, useState} from 'react'
 

function Contacts() {

 
	return (
		<div className="text-gray-400 bg-gray-900 lg:w-screen lg:h-screen  relative" >
                <iframe   title="map" className="absolute inset-0  lg:w-screen lg:h-screen  w-full h-full " 
                    frameborder="0" marginheight="0" marginwidth="0" scrolling="no" 
                    src="https://maps.google.com/maps?width=100%&amp;height=100%&amp;hl=en&amp;q=Zincirlikuyu%20Regus%20&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" 
                    style={{filter: "grayscale(0.6) contrast(0.8) opacity(0.30)"}}
                    ></iframe>
                <div className="container py-24 mx-auto flex sm:flex-nowrap flex-wrap">
                    <div className=" bg-gray-900 rounded-lg overflow-hidden  p-10  mt-20 flex items-end justify-start relative">

                    <div className="bg-gray-900  relative flex flex-wrap py-6 rounded shadow-md">
                        <div className="lg:w-1/2 px-6">
                        <h2 className="title-font font-semibold text-white tracking-widest text-xs">ADRES:</h2>
                            <p className="mt-1">
                            Esentepe Mah. Yüzbaşı Kaya Aldogan Sk. Caddesi D:No:4 34394
                                                                 Şişli/İstanbul
 
                            </p>
                        </div>
                        <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                        <h2 className="title-font font-semibold text-white tracking-widest text-xs">EMAIL</h2>
                        <a className="text-indigo-400 leading-relaxed" href="mail:adastrai.com">info@adastrai.com</a>
                        <h2 className="title-font font-semibold text-white tracking-widest text-xs mt-4">TELEFON:</h2>
                        <a className="leading-relaxed" href="tel:+905321549955">+90 532 154 99 55</a><br/>
                        <a className="leading-relaxed" href="tel:+902124011396">+90 212 401 13 96</a>

                        </div>
                    </div>
                    </div>
                    <div className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                    
                    </div>
                </div>
                </div>
	)
}

export default Contacts