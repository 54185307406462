import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function About() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
       
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">adastrai.com</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Hakkımızda</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                  İş süreçlerinizi yenilikçi çözümlerle dönüştürmeye hazırız. Adastrai.com olarak, sektörde 25 yıllık derin deneyimimizle piyasaya farklı bir bakış açısı getiriyoruz.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="/image/_e26a2408-46b3-4939-8cd9-46cf2e6de8c9.webp"
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900">Ad Astra'nın Kökenleri</h2>

              <p>
              

              Ad Astra’nın Anlamı “Per aspera, ad astra” sözü, Adastrai.com’un ruhunu yansıtıyor. 
              Bu söz, zorlukların üstesinden gelerek yıldızlara ulaşmayı simgeliyor. 
              Bu hedef doğrultusunda, teknolojik gelişime ve dönüşüme katkıda bulunuyoruz.     
              </p>
              <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">Vizyon & Misyon</h2>

              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Vizyonumuz: </strong> 
                    Teknolojik çözümlerimizle iş dünyasının zorluklarını çözerek, müşterilerimizin sektörlerinde öncü olmalarını sağlamak. 
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Misyonumuz:</strong> 
                    Müşterilerimize özel, etkili ve sürdürülebilir çözümler sunarak işlerini geliştirmelerine yardımcı olmak. Yenilikçi yaklaşımımız ve 25 yıllık tecrübemizle, iş ortaklarımızın başarı öykülerine ortak oluyoruz.

                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Sektördeki Deneyimimiz </h2>

              <p className="mt-8">
                  Sektördeki 25 yılı aşkın deneyimimiz, 
                  bize teknolojik yeniliklere ve sektör değişimlerine uyum sağlama becerisi kazandırdı. 
                  Adastrai.com’un arkasında, bu süreçte edindiğimiz bilgi ve tecrübe yatmaktadır. 
                  

              </p>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}