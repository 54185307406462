import {useState, useEffect} from 'react'
import Reviews from 'api/reviewTraining.json'
import ReviewItem from 'components/ui/ReviewItem'
import { CheckCircleIcon  } from '@heroicons/react/20/solid'


export default function Training() 

{
  const [reviews, setReviews] = useState([])

	useEffect(() => {
		setReviews(Reviews)
	}, [])

  
  return ( 
    <div className="relative overflow-hidden ">
    <img 
            className={`absolute w-full  scale-[1.3]`} 
            src="https://preview.cruip.com/job-board/images/hero-illustration.svg" 
            width="2120" 
            height="931"
            alt="adastrai.com"
          />
 
    <div  data-aos="fade-in" data-aos-duration="0" className="container px-8 pt-32 mx-8  mx-auto   rounded-lg" >
      <div className="  w-full mb-10">
        <div className="lg:w-1/2 w-full mb-5 pb-5 lg:mb-0">
          <h1 className="sm:text-3xl text-2xl font-semibold title-font mb-2 "  data-aos="fade-in">Eğitim Programlarımız</h1>
          <div className="h-1 w-80 bg-indigo-500 rounded "></div>
        </div>
        <p className="lg:w-1/2 w-full leading-relaxed text-xl  "> Eğitimlerimiz, yapay zeka dünyasına adım atmak isteyenlerden, bu alanda uzmanlaşmak isteyen profesyonellere kadar geniş bir yelpazede hazırlanmıştır. Pratik ve teorik bilgiyi harmanlayarak, katılımcıların gerçek dünya problemlerine çözüm üretmelerini amaçlamaktayız. </p>
      </div>
      <div className="flex flex-wrap -m-8">
        <div className="xl:w-1/3 md:w-1/2 p-4 ">
          <div className="bg-gray-50 rounded-lg h-full	shadow-md" data-aos="fade-up"
                                                data-aos-easing="ease-out-cubic"
                                                data-aos-delay="50"
                                                >
        
            <img className="h-40 rounded-t-lg w-full object-cover p-0 object-left mb-6" src="/image/learn-start.webp" alt="content" />
            <h3 className="tracking-widest text-indigo-500 text-xs  px-6 font-medium title-font"> Temel kavramlar, ilk adımlar ve uygulamalar.</h3>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-4 px-6 ">Giriş Seviyesi Yapay Zeka Eğitimi</h2>
            <p className="leading-relaxed text-base text-gray-900 px-6 pb-6  "> Bu eğitimde, yapay zekanın temel prensipleri, algoritmalara giriş ve yapay zeka uygulamalarının temelleri ele alınmaktadır. Katılımcılar, bu eğitim ile sektörde kullanılan anahtar kavramları öğrenirken, basit yapay zeka projeleri oluşturma yeteneği kazanacaklar. </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4 ">
          <div className="bg-gray-50 rounded-lg h-full	shadow-md" data-aos="fade-up"
                                                data-aos-easing="ease-out-cubic"
                                                 data-aos-delay="200">
            <img className="h-40 rounded-t-lg w-full object-cover p-0 object-center mb-6" src="/image/learn-ad.webp" alt="content" />
            <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font px-6">Derin öğrenme, özel algoritmalar ve sektörel uygulamalar.</h3>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-4  px-6">İleri Seviye Yapay Zeka Eğitimi</h2>
            <p className="leading-relaxed text-base text-gray-900 px-6 pb-6 "> Bu ileri seviye eğitim, deneyimli katılımcılar için tasarlanmıştır. Derin öğrenme, algoritmalar ve sektörel uygulamalar üzerinde durulacak. Katılımcılar, modelleri eğitme ve gerçek sorunlara uygulama konusunda yetkinlik kazanacaklar. </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4 "  data-aos="fade-up"
                                                data-aos-easing="ease-out-cubic"
                                               data-aos-delay="350">
          <div className="bg-gray-50 rounded-lg h-full	shadow-md">
            <img className="h-40 rounded-t-lg w-full object-cover p-0 object-right mb-6" src="/image/learn-private.webp" alt="content" />
            <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font  px-6">Şirketinizin ihtiyaçlarına göre özel içerik ve dersler.</h3>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-4  px-6">Özelleştirilmiş Eğitim Programları</h2>
            <p className="leading-relaxed text-base text-gray-900 px-6 pb-6 "> Özel ihtiyaçlarınıza uygun bir eğitim programı mı arıyorsunuz? Şirketinizin karşılaştığı özel zorluklara ve hedeflere göre özelleştirilmiş bir eğitim programı oluşturabiliriz. Bu programlar, sektörünüze özgü gerçek senaryolar üzerine inşa edilerek, ekibinizin mevcut sorunlara yapay zeka çözümleri üretmelerini sağlar. </p>
          </div>
        </div>
      </div>
    </div>
    <div data-aos="fade-in"   data-aos-duration="0"  className="relative hidden  h-full shadow-[10px_5px_30px_10px_rgba(0,0,0,0.1)] isolate overflow-hidden bg-gray-50 mt-10 px-6 py-6 sm:py-6 lg:px-8">
    
      <div  className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <figure>
          <blockquote className="text-center text-3xl font-review text-blue-800 tracking-wide leading-8 sm:text-3xl sm:leading-9"  
                                                data-aos="fade-in"
                                                data-aos-easing="ease-out-cubic"
                                                 data-aos-delay="50">
            <p> “Yapay zeka kodların ve algoritmaların ötesindedir; öğrenmek, uygulamak ve yenilikçi bir geleceği birlikte inşa etmektir. Eğitimlerimizle sizi bu heyecan verici yolculuğa davet ediyoruz.” </p>
          </blockquote>
          <figcaption className="mt-10"   data-aos="fade-in"
                                                data-aos-easing="ease-out-cubic"
                                                 data-aos-delay="1050">
            <img className="mx-auto h-20 w-20 rounded-full shadow-xl" src="/image/ismailyurtsever.jpeg" alt="" />
            <div className="mt-4 flex items-center justify-center space-x-3 text-base">
              <div className="font-semibold">İsmail Yurtsever </div>
              <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <div className="opacity-60"> Ad Astra Yazılım Kurucusu</div>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>

    <div data-aos="fade-in" className="relative  h-full shadow-[10px_5px_30px_10px_rgba(0,0,0,0.1)] isolate overflow-hidden bg-[#0540F2] mt-10 px-6 py-6 sm:py-6 lg:px-8">
    
    <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-[#0C59F2] shadow-xl shadow-[#030BA6]   sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
    <div className="mx-auto max-w-2xl lg:max-w-4xl">
      <figure>
        <blockquote className="text-center text-3xl font-review text-[#F2F2F2] tracking-wide leading-8 sm:text-3xl sm:leading-9"
        >
          <p> “Yapay zeka kodların ve algoritmaların ötesindedir; öğrenmek, uygulamak ve yenilikçi bir geleceği birlikte inşa etmektir. Eğitimlerimizle sizi bu heyecan verici yolculuğa davet ediyoruz.” </p>
        </blockquote>
        <figcaption className="mt-10"    >
          <img className="mx-auto h-20 w-20 rounded-full shadow-xl" src="/image/ismailyurtsever.jpeg" alt="" />
          <div className="mt-4 flex items-center justify-center space-x-3 text-base text-[#7EA5F2]">
            <div className="font-semibold">İsmail Yurtsever </div>
            <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
              <circle cx={1} cy={1} r={1} />
            </svg>
            <div className="opacity-60"> Ad Astra Yazılım Kurucusu</div>
          </div>
        </figcaption>
      </figure>
    </div>
  </div>
 
  

    <div className="container relative  px-8 py-6 mt-10 mx-auto  lg:sticky">
    <img 
            className={`absolute w-full  scale-[1.3]`} 
            src="https://preview.cruip.com/job-board/images/hero-illustration.svg" 
            width="2120" 
            height="931"
            alt="adastrai.com"
          />
      <div className="  w-full mb-10 lg:mb-6 rounded-lg overflow-hidden">

         
        <div className="    ">
        <div className="mb-5 pb-5  lg:mb-0">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 ">Atölye Çalışmaları</h1>
          <div className="h-1 w-80 bg-indigo-500 rounded "></div>
        </div>
        <p className="py-3 w-full leading-relaxed text-xl  "> 
        Atölye çalışmalarımız, teorik bilginin ötesine geçerek, yapay zeka ve derin öğrenme konularını uygulamalı bir şekilde deneyimlemenizi sağlar. Gerçek dünya senaryoları, sektörel uygulamalar ve interaktif oturumlarla bilgilerinizi pekiştirirken aynı zamanda yeni yetenekler kazanma fırsatı bulacaksınız. Her seviyeden katılımcının becerilerini geliştirebileceği bu atölyeler, alanında uzman eğitmenler eşliğinde gerçekleştirilmektedir. 
        </p>        
      
          <div  className="flex flex-column flex-wrap px-5 py-5 items-stretch justify-center">
           <div className="lg:w-5/12 mr-4 md:gap-2 xl:gap-2 px-5 py-5 my-2 bg-gray-50 rounded-lg shadow-md" data-aos="fade-up"
                                                data-aos-easing="ease-out-cubic"
                                                data-aos-delay="50">
            
                  <img className="rounded-xl" src="/image/learn-1.webp" alt="Pratik Yapay Zeka Uygulamaları "/>
            
                    <div className="space-y-2 md:space-y-4">
                      <h2 className="text-2xl text-gray-900 font-medium title-font   ">
                      Pratik Yapay Zeka Uygulamaları
                      </h2>
                      <p className="leading-relaxed text-base text-gray-900 pb-2 ">
                        Gerçek hayat senaryoları üzerinden uygulamalı eğitimler. Bu atölye, teorik bilgileri pratiğe dökmek isteyenler için idealdir. Katılımcılar, gerçek dünyanın problemlerini çözmek için yapay zeka tekniklerini nasıl kullanacaklarına dair elle tutulur deneyimler kazanacaklar.
                      </p>
                    </div>
                    <ul   className="space-y-1 sm:space-y-2 mt-0">
                      <li className="flex space-x-3">
                        
                      <CheckCircleIcon className="h-6 w-6 text-[#1D9BF0]" aria-hidden="true" />

                        <span className="text-md sm:text-md text-gray-500">
                          <span className="font-bold">Uzman eğitmenler </span> tarafından verilen kaliteli içerik.
                        </span>
                      </li>

                      <li className="flex space-x-3">
                        
                        
                      <CheckCircleIcon className="h-6 w-6 text-[#1D9BF0]" aria-hidden="true" />
                        <span className="text-md sm:text-md text-gray-500">
                        Pratik ve teorik bilginin  <span className="font-bold">mükemmel dengesi.</span>
                        </span>
                      </li>

                      <li className="flex space-x-3">
                      <CheckCircleIcon className="h-6 w-6 text-[#1D9BF0]" aria-hidden="true" />
                        <span className="text-md sm:text-md text-gray-500">
                        Gerçek dünya örnekleriyle zenginleştirilmiş eğitim materyalleri.
                        </span>
                      </li>
                    </ul>

                  </div>
          <div className="lg:w-5/12 mr-4 md:gap-2 xl:gap-2 px-5 py-5 my-2 bg-gray-50 rounded-lg shadow-md" data-aos="fade-up"
                                                data-aos-easing="ease-out-cubic"
                                                 data-aos-delay="250">
            
            <img className="rounded-xl" src="/image/learn-2.webp" alt="Pratik Yapay Zeka Uygulamaları"/>
      
              <div className="space-y-2 md:space-y-4">
                <h2 className="text-2xl text-gray-900 font-medium title-font   ">
               Sektörel Yapay Zeka Stratejileri 
                </h2>
                <p className="leading-relaxed text-base text-gray-900 pb-2 ">
                 Farklı sektörlere özgü yapay zeka uygulamaları üzerine odaklanır. Katılımcılar, sektörel ihtiyaçlara ve zorluklara nasıl yanıt verileceğini öğrenerek, sektörlerinde lider olma yolunda büyük bir adım atacaklar.                     
                </p>
              </div>
              <ul className="space-y-1 sm:space-y-2 mt-0">
                <li className="flex space-x-3">
                  
      
                  
                <CheckCircleIcon className="h-6 w-6 text-[#1D9BF0]" aria-hidden="true" />
                  <span className="text-md sm:text-md text-gray-500">
Özelleştirilmiş <span className="font-bold">sektör bazlı</span> yaklaşımlarla derinlemesine bilgi.
                  </span>
                </li>

                <li className="flex space-x-3">
                  
        
                  
                <CheckCircleIcon className="h-6 w-6 text-[#1D9BF0]" aria-hidden="true" />
                  <span className="text-md sm:text-md text-gray-500">
<span className="font-bold">Sektörel zorluklara</span> yapay zeka çözümleriyle pratik yanıtlar.mükemmel dengesi.
                  </span>
                </li>

                <li className="flex space-x-3">
   
                <CheckCircleIcon className="h-6 w-6 text-[#1D9BF0]" aria-hidden="true" />
                  <span className="text-md sm:text-md text-gray-500">
Rekabetçi avantaj kazanmak için stratejik içgörüler ve en iyi uygulamalar.                        </span>
                </li>
              </ul>

            </div>
          </div>
  
            
        
      </div>
      </div>
      
    </div>
    {reviews && (<ReviewItem reviews={reviews}/>)}
  
  </div> ) }