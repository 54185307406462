export default function Training() 

{
    return (
<div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
    <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-6 lg:gap-x-12">
        <div className="lg:col-span-2">
            <div className="py-8 lg:pr-4 lg:pr-8">
                <div className="space-y-5 lg:space-y-8">
                    <a className="inline-flex items-center gap-x-1.5 text-sm text-gray-600 decoration-2 hover:underline dark:text-blue-400" href="#">
                        <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        Geri Dön
                    </a>

                    <h2 className="text-3xl font-bold lg:text-4xl lg:text-5xl dark:text-white">Süre doldu… Hamas, Aşkelon’a saldırı başlattı</h2>

                    <p className="text-lg text-gray-800 dark:text-gray-200">Hamas, Gazze yakınında yer alan İsrail'in Aşkelon kentine saldıracağını duyurmasının ardından bölgede yoğun füze saldırısı ve çatışmalar başladı. Hamas, Aşkelon sakinlerine bölgeyi saat 17.00'ye kadar terk etmeleri uyarısında bulunmuştu.</p>

                    <img className="w-full object-cover rounded-xl mt-4" src="https://i.sozcucdn.com/wp-content/uploads/2023/10/10/sure-doldu-hamas-askelona-saldiri-baslatti.jpeg?w=1200&h=675&mode=crop" alt="Hamas, Aşkelon’a saldırı başlattı"/>

                    <p className="text-lg text-gray-800 dark:text-gray-200 mt-4">Hamas’ın silahlı kanadı İzzeddin el-Kassam Tugayları, İsrail’in Askalan (Aşkelon) kenti sakinlerine bölgeyi terk etmeleri için yerel saatle 17.00’ye kadar süre tanıdığını açıklamıştı.Uyarı sonucu binlerce sivil Aşkelon’dan ayrılırken Hamas saldırıları başlattı. Sürenin dolmasının ardından abluka altındaki Gazze Şeridi’nden Aşkelon şehrine çok sayıda roket fırlatIıldı. Bölgede çatışmaların da meydana geldiği belirtildi.</p>
 
                </div>
            </div>
        </div>
    </div>
</div>
  
 )
}

