import {useState, useEffect} from 'react'
import Reviews from 'api/reviewAIConsulting.json'
import ReviewItem from 'components/ui/ReviewItem'
import { PhoneIcon, EnvelopeIcon, MapPinIcon, ClockIcon } from '@heroicons/react/24/outline';


import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import { CalendarDaysIcon, HandRaisedIcon, ArrowPathIcon, FingerPrintIcon, GlobeAltIcon, LightBulbIcon, ScaleIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Geliştirdiğimiz Yazılımlar için Bütünsel Yaklaşımımız',
    description:
      'Yazılım geliştirme sürecimiz, ihtiyaç analizinden başlar ve müşterilerimizin iş akışlarına en uygun çözümleri tasarlayarak, geliştirme ve entegrasyon sürecine devam eder. Projelerimizi tamamlamak için kalite kontrol ve test aşamalarını gerçekleştiriyoruz.',
    icon: GlobeAltIcon, // Bütünsel yaklaşım için dünya ikonu uygun olabilir.
  },
  {
    name: 'Geliştirilmiş Yazılım Çözümleri ile Hedeflerinizi Gerçekleştirin    ',
    description:
      'Her işletmenin benzersiz ihtiyaçları vardır ve biz, Ad Astra Yazılım olarak, müşterilerimizin hedeflerine ulaşmalarını sağlayacak özel yazılım çözümleri geliştiriyoruz. Mobil uygulamalardan web platformlarına, entegre sistemlerden veri analitiği çözümlerine kadar geniş bir yelpazede hizmet veriyoruz.',
    icon: LockClosedIcon, // Özelleştirilmiş çözümler için kilit ikonu uygun.
  },
  {
    name: 'Güvenilir ve Sürdürülebilir Kodlama Pratikleri',
    description:
      'Ad Astra Yazılım, projelerimizin temelini oluşturan güvenilir ve sürdürülebilir kodlama uygulamalarını sunar. En güncel teknolojileri kullanarak ve endüstri standartlarına uygun kodlama yaparak, projelerinizin uzun vadeli başarısını garanti altına alırız.',
    icon: ScaleIcon, // Terazi ikonu güvenilirlik ve sürdürülebilirlik için uygun bir simgedir.
  },
  {
    name: 'Sürekli Destek ve Bakım',
    description:
      'Yazılım geliştirmede iş birliğimiz projenin teslimiyle sona ermez. Yazılımlarınızın güncel ve verimli kalmasını sağlamak için sürekli destek ve bakım hizmetleri sunuyoruz.',
    icon: ServerIcon, // Sürekli destek ve bakım için server ikonu uygun bir simgedir.
  },
  {
    name: 'Ad Astra Yazılım ile Neler Başarabilirsiniz?',
    description:
      'Ad Astra Yazılım olarak, işletmelerin dönüşümüne öncülük ediyoruz. İş süreçlerinizi optimize eden, verimliliği artıran ve müşteri deneyimini iyileştiren çözümler sunuyoruz. Veri analitiği ve raporlama araçlarımızla işletmenizin önemli verilerini anlamlandırıp stratejik kararlarınızı destekliyoruz. Doğru verilere sahip olarak piyasa trendlerini öngörebilir ve işletmenizi daha bilinçli bir şekilde yönlendirebilirsiniz.',
    icon: LightBulbIcon, // Ampul ikonu başarı ve aydınlanma için uygun bir simgedir.
  },
  {
    name: 'Teknolojide Liderlik: Ad Astra Yazılım\'ın Yaklaşımı',
    description:
      'Ad Astra Yazılım olarak, sürekli değişen teknoloji dünyasında liderliğimizi korumak için yenilikçi ve esnek yaklaşımlar benimsiyoruz. En yeni teknolojik trendleri takip ederek, müşterilerimize rekabet avantajı sağlayacak yazılım çözümleri sunuyoruz.',
    icon: HandRaisedIcon, // El kaldıran kişi ikonu liderlik için uygun bir simgedir.
  }
]
 
export default function Development() {
  const [reviews, setReviews] = useState([])

	useEffect(() => {
		setReviews(Reviews)
	}, [])

	
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submit logic here
    console.log(formData);
  };
  return (
     
 <div className="mx-auto">


<div className=" mx-auto w-full bg-sky-900">
<div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto ">
 
  <div className="lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
    <div className="lg:col-span-7">
    
      <div className="grid grid-cols-12 gap-2 sm:gap-6 items-center lg:-translate-x-10">
        <div className="col-span-3">
          <img className="rounded-xl" src="/image/photoofamaninhis40s.webp" alt="Image Description"/>
        </div>
    

        <div className="col-span-4">
          <img className="rounded-xl" src="/image/_7fe46964-9df5-46ca-a528-5e76b34f0c59.webp" alt="Image Description"/>
        </div>
       

        <div className="col-span-5">
          <img className="rounded-xl" src="/image/dalle-2.webp" alt="Image Description"/>
        </div>
  
      </div>
     
    </div>
 

    <div className=" relative mt-5 sm:mt-10 lg:mt-0 lg:col-span-5  ">
   
      <div className="space-y-6 sm:space-y-8 ">
      
        <div className="space-y-2 md:space-y-4">
          <h2 className="font-bold text-3xl lg:text-4xl text-gray-300 dark:text-gray-200">
          Ad Astra Yazılım'ın Yazılım Hizmetleri: İş Süreçlerinizde Mükemmelliği Yakalayın



          </h2>
          <p className="text-gray-300">
          İş akışlarınızı yeniden tasarlayarak, gereksiz adımları ortadan kaldırıyor ve verimliliği en üst düzeye çıkarıyoruz. Ad Astra Yazılım'ın özel yazılım çözümleriyle, zaman ve kaynak israfını önleyerek iş süreçlerinizde mükemmelliği yakalayabilirsiniz.
          </p>
        </div>
     
        <ul role="list" className="space-y-2 sm:space-y-4">
          <li className="flex space-x-3">
     
            <svg className="flex-shrink-0 h-6 w-6 text-blue-200 dark:text-blue-100" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z" fill="currentColor" fill-opacity="0.1"/>
              <path d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z" fill="currentColor"/>
            </svg>
 

            <span className="text-sm sm:text-base text-gray-300">
              <span className="font-bold">Verimliliğinizi Artırın:</span> 
              Manuel hataları en aza indirgeyerek ve operasyonel verimliliği artırarak işletmenizin genel performansını yükseltiyoruz. İleri teknoloji otomasyon ve akıllı sistemlerimizle verimliliğinizi maksimum seviyeye çıkarın.
            </span>
          </li>

          <li className="flex space-x-3">
   
            <svg className="flex-shrink-0 h-6 w-6 text-blue-200 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z" fill="currentColor" fill-opacity="0.1"/>
              <path d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z" fill="currentColor"/>
            </svg>
     

            <span className="text-sm sm:text-base text-gray-300">
              <span className="font-bold">
                Karar Verme Süreçlerinizi Güçlendirin:</span> 
                Veri analitiği ve raporlama araçlarımızla işletmenizin önemli verilerini anlamlandırıyor ve stratejik karar süreçlerinizi destekliyoruz. Güçlü kararlar almanız için gelişmiş analitik çözümler sunuyoruz.
            </span>
          </li>

          <li className="flex space-x-3">
    
            <svg className="flex-shrink-0 h-6 w-6 text-blue-200  " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z" fill="currentColor" fill-opacity="0.1"/>
              <path d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z" fill="currentColor"/>
            </svg>
     
            <span className="text-sm sm:text-base text-gray-300">
               <span className="font-bold">Geleceğe Hazırlanın:</span> 
               Teknolojik gelişmeleri yakından takip ediyor ve işletmenizi geleceğe hazırlıyoruz. Bulut bilişim, yapay zeka, büyük veri ve nesnelerin interneti gibi ileri teknolojilerle işletmenizi rekabet ortamında öne çıkarıyoruz.

AdAstra Yazılım ile iş süreçlerinizdeki her adımı daha verimli, daha akıllı ve geleceğe daha hazır bir şekilde yönetin. İleri teknoloji çözümlerimizle işletmenizi daha etkili hale getirerek başarıya giden yolda size rehberlik ediyoruz.
            </span>
          </li>
        </ul>
      
      </div>
    </div>
  
  </div>
 
</div>
</div>
<div className="relative overflow-hidden ">
<img 
        className={`absolute w-full  scale-[1.3]`} 
        src="https://preview.cruip.com/job-board/images/hero-illustration.svg" 
        width="2120" 
        height="931"
        alt="adastrai.com"
      />
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-3xl font-semibold leading-7 text-indigo-600 font-review">Kodluyoruz, Dönüştürüyoruz, İnovasyonla Buluşturuyoruz!</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Ad Astra Yazılım, 25 yılı aşkın deneyimiyle, her işletmenin ihtiyaçlarına özel yazılım çözümleri sunmaktadır. Müşterilerimizin ihtiyaçlarına en uygun çözümleri üretmek için derin teknik bilgi birikimine ve sektörel deneyime sahibiz. İşletmenizin büyüklüğü ne olursa olsun, bize güvenebilirsiniz.


          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base  text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
</div>

    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">

      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">İletişime Geçin ve İşletmenizin Geleceğini Şekillendirelim</p>
              <h1 className="mt-2 text-base font-bold tracking-tight text-gray-900 sm:text-2xl">
              Ad Astra Yazılım olarak, işletmenizin hizmetine teknolojinin gücünü sunmak için buradayız. 
                </h1>
              <p className="mt-6 text-base leading-8 text-gray-700">
              Size, iş süreçlerinizi dönüştürmek ve dijital dönüşüm yolculuğunuzda size eşlik etmek için yazılım çözümleri sunuyoruz. İhtiyaçlarınızı karşılamak için özel yazılım geliştirme veya mevcut sistemlerinizi iyileştirme konularında uzmanız. Birlikte işletmenizin geleceğini şekillendirelim!
              </p>

              <div className=" mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold text-center mb-8">Bize Ulaşın</h2>
      <div className="max-w-md mx-auto space-y-4">
        <div className="flex items-center">
          <PhoneIcon className="h-4 w-4 text-blue-500 mr-2" />
          <p className="text-base text-gray-900  p-1"> Telefon:  <a href="tel:+902124011396">+90 212 401 13 96 </a>| </p>
          <p className="text-base text-gray-900">  <a href="tel:+905321549955">+90 532 154 99 55</a></p>

        </div>
        <div className="flex items-center">
          <EnvelopeIcon className="h-4 w-4 text-blue-500 mr-2" />
          <p className="text-base text-gray-900"> E-posta: <a href="mail:info@adastrai.com">info@adastrai.com</a></p>
        </div>
        <div className="flex items-center">
          <MapPinIcon className="h-6 w-6 text-blue-500 mr-2" />
          <p className="text-base text-gray-900"> Adres:  Esentepe, Yüzbaşı Kaya Aldogan Sk. No:4, 34394 Şişli/İstanbul</p>
        </div>
        <div className="flex items-center">
          <ClockIcon className="h-5 w-5 text-blue-500 mr-2" />
          <p className="text-base text-gray-900"> Çalışma Saatleri: Hafta içi 09:00 - 18:00</p>
        </div>
      </div>
    </div>

            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="https://lh5.googleusercontent.com/p/AF1QipM56JBO6_fJgEIiHHf2iYXhmy_zPwF574rntGNE"
            alt=""
          />
        </div>
       
      </div>
    </div>

    {reviews && (<ReviewItem reviews={reviews}/>)}
    </div>

  )
}
