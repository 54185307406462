import {useState, useEffect} from 'react'
import Reviews from 'api/reviewAIConsulting.json'
import ReviewItem from 'components/ui/ReviewItem'
import { PhoneIcon, EnvelopeIcon, MapPinIcon, ClockIcon } from '@heroicons/react/24/outline';


import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import { CalendarDaysIcon, HandRaisedIcon, ArrowPathIcon, FingerPrintIcon, GlobeAltIcon, LightBulbIcon, ScaleIcon } from '@heroicons/react/24/outline'
import About from './../About';

const features = [
  {
    name: 'Yazılım Geliştirmede Bütünsel Yaklaşımımız',
    description:
      'Yazılım geliştirme sürecimiz, ihtiyaç analizi ile başlar ve müşterilerimizin iş akışlarına en uygun çözümleri tasarlama, geliştirme ve entegre etme süreciyle devam eder. Kalite kontrol ve test aşamaları ile projelerimizi tamamlıyoruz.',
    icon: GlobeAltIcon, // Bütünsel yaklaşım için dünya ikonu uygun olabilir.
  },
  {
    name: 'Özelleştirilmiş Yazılım Çözümleri',
    description:
      'Her işletmenin benzersiz ihtiyaçları vardır ve biz, Ad Astra Yazılım olarak, müşterilerimizin hedeflerine ulaşmalarını sağlayacak özel yazılım çözümleri geliştiriyoruz. Mobil uygulamalardan web platformlarına, entegre sistemlerden veri analitiği çözümlerine kadar geniş bir yelpazede hizmet veriyoruz.',
    icon: LockClosedIcon, // Özelleştirilmiş çözümler için kilit ikonu uygun.
  },
  {
    name: 'Güvenilir ve Sürdürülebilir Kodlama Pratikleri',
    description:
      'Kaliteli ve sürdürülebilir kodlama uygulamaları, projelerimizin temel taşıdır. Ad Astra Yazılım, en güncel teknolojileri kullanarak ve endüstri standartlarına uygun kodlama yaparak projelerin uzun vadeli başarısını garanti altına alır.',
    icon: ScaleIcon, // Güvenilirlik ve sürdürülebilirlik için terazi ikonu uygun olabilir.
  },
  {
    name: 'Sürekli Destek ve Bakım',
    description:
      'Yazılım geliştirmede iş birliğimiz, projenin teslimiyle sona ermez. Sürekli destek ve bakım hizmetlerimizle yazılımlarınızın güncel ve verimli kalmasını sağlıyoruz.',
    icon: ServerIcon, // Sürekli destek ve bakım için server ikonu uygun.
  },
  {
    name: 'Ad Astra Yazılım ile Neler Başarabilirsiniz?',
    description:
      'Yazılım geliştirmenin ötesinde, işletmelerin dönüşümüne öncülük ediyoruz. İş süreçlerini optimize eden, verimliliği artıran ve müşteri deneyimini iyileştiren çözümler sunuyoruz. Veri analitiği ve raporlama araçlarımızla, işletmenizin önemli verilerini anlamlandırıyor ve stratejik karar verme süreçlerinizi destekliyoruz. Doğru verilerle donanmış olarak, piyasa trendlerini öngörebilir ve işletmenizi daha bilinçli bir şekilde yönlendirebilirsiniz.',
    icon: LightBulbIcon, // Başarı ve aydınlanma için ampul ikonu uygun olabilir.
  },
  {
    name: 'Teknolojide Liderlik: Ad Astra Yazılım\'ın Yaklaşımı',
    description:
      'Ad Astra Yazılım olarak, sürekli değişen teknoloji dünyasında liderliğimizi korumak için yenilikçi ve esnek yaklaşımlar benimsiyoruz. En yeni teknolojik trendleri takip ederek, müşterilerimize rekabet avantajı sağlayacak yazılım çözümleri sunuyoruz.',
    icon: HandRaisedIcon, // Liderlik için el kaldıran kişi ikonu uygun olabilir.
  } 
]

 
export default function Development() {

  const [activeTab, setActiveTab] = useState(1);
  const [autoCycle, setAutoCycle] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      return;
    }

    if (!autoCycle) return;

    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab < 3 ? prevTab + 1 : 1));
    }, 3000); // Örneğin 3 saniye aralıklarla

    return () => clearInterval(interval);
  }, [activeTab, autoCycle]);

  const stopAutoCycle = () => setAutoCycle(false);

  const [reviews, setReviews] = useState([])

	useEffect(() => {
		setReviews(Reviews)
	}, [])

	
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submit logic here
    console.log(formData);
  };
  return (
     
 <div className="mx-auto">


<div className="relative overflow-hidden bg-gray-100    ">


    <div className="z-41  py-12 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-4xl font-bold  leading-2">Zorluklardan Yıldızlara: Web Sitenizi Geliştirmek İçin Bize Güvenin!</h2>
 
          <p className="mt-6 text-xl leading-8 text-gray-600">
          Ad Astra Yazılım olarak 25 yıllık deneyimimizle, her türlü işletmenin ihtiyaçlarına yönelik özelleştirilmiş yazılım çözümleri sunmaktayız. Sahip olduğumuz derin teknik bilgi birikimi ve sektörel deneyimimiz sayesinde, müşterilerimizin ihtiyaçlarına en uygun çözümleri geliştiriyoruz. Web sitenizi yükseltmek ve başarıya ulaşmak için bize güvenebilirsiniz!
          </p>
        </div>
 
      <div class="group hidden pt-4 space-y-8 border-t-4 border-gray-100 dark:border-gray-800">
        <div class="mx-auto   h-32 w-32 rotate-45 overflow-hidden rounded-[2rem]">
          <img
            class="mx-auto h-full w-full -rotate-45 scale-125 object-cover transition duration-300 group-hover:scale-[1.3]"
            src="/image/ismailyurtsever.jpeg"
            alt="ismail yurtsever"
            loading="lazy"
            width="640"
            height="805"
          />
        </div>
        <div class="space-y-4 text-center">
          <div>
            <h4 class="text-2xl text-gray-700 dark:text-white">İsmail Yurtsever</h4>
            <span class="block text-sm text-gray-500">CEO-Founder</span>
          </div>
         
        </div>
        
      </div>
 
      </div>
    </div>
</div>
 <div className="hidden lg:block mx-auto w-full bg-sky-700">
 <div className=" sm:px-6 lg:px-8 lg:py-14 mx-auto">
 
        <div className="relative p-6 md:p-16">
          <div className="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-top">
            <div className="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
        

        
              {/* Tab Navs */}
              <nav className="grid gap-4 " aria-label="Tabs" role="tablist">
                {/* Tab 1 */}
                <button 
                    onMouseEnter={() => {setActiveTab(1); stopAutoCycle();}}
                    onClick={() => {setActiveTab(1); stopAutoCycle();}}
                    className={`${activeTab === 1 ? 'bg-sky-200 border-2  border-gray-200 scale-110' : 'border-0 bg-sky-50'} relative transition-all ease-in-out duration-300 text-left p-4 md:p-5 rounded-xl  hover:bg-gray-200 hs-tab-active:shadow-md hs-tab-active:hover:border-transparent`} 
                    type="button" 
                    id="tabs-with-card-item-1" data-hs-tab="#tabs-with-card-1" aria-controls="tabs-with-card-1" role="tab">
                               
                           <span class="flex">
                            <svg class="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                              <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                              <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                            </svg>
                            <span class="grow ml-6">
                              <span class="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">
                              İlham Veren Tasarımlar:</span>
                              <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">
                              Ziyaretçilerinizin İlgisini Çekin.

                          </span>
                            </span>
                          </span>
               
                </button>

                {/* Tab 2 */}
                <button 
                    onMouseEnter={() => {setActiveTab(2); stopAutoCycle();}}
                    onClick={() => {setActiveTab(2); stopAutoCycle();}} 
                    type="button" 
                    className={`${activeTab === 2 ? 'bg-sky-200 border-2  border-gray-200 scale-110' : 'border-0 bg-sky-50'} relative transition-all ease-in-out duration-300 text-left p-4 md:p-5 rounded-xl  hover:bg-gray-200 hs-tab-active:shadow-md hs-tab-active:hover:border-transparent`} 
                    id="tabs-with-card-item-2" data-hs-tab="#tabs-with-card-2" aria-controls="tabs-with-card-2" role="tab">

                      <span class="flex">
                            <svg class="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                              <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                              <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                            </svg>
                            <span class="grow ml-6">
                              <span class="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200"> 
                             Dönüştürün:</span>
                              <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">
                              Statik Sayfalarınızı İlgi Çekici Bir Yolculuğa Dönüştürün.                              </span>
                            </span>
                        </span>
                    

                </button>

                {/* Tab 3 */}
                <button 
                    onMouseEnter={() => {setActiveTab(3); stopAutoCycle();}}
                    onClick={() => {setActiveTab(3); stopAutoCycle();}} 
                    type="button" 
                    className={`${activeTab === 3 ? 'bg-sky-200 border-2  border-gray-200 scale-110' : 'border-0 bg-sky-50'} transition-all ease-in-out duration-300 text-left p-4 md:p-5 rounded-xl  hover:bg-gray-200 hs-tab-active:shadow-md hs-tab-active:hover:border-transparent`} 
                    id="tabs-with-card-item-3" data-hs-tab="#tabs-with-card-3" aria-controls="tabs-with-card-3" role="tab">
                                            <span class="flex">
                            <svg class="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                              <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                              <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                            </svg>
                            <span class="grow ml-6">
                              <span class="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200"> 
                               Geleceğin Teknolojilerini Bugünden Keşfedin:


                              </span>
                              <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">
                              Ad Astra Yazılım ile Yıldızlara Ulaşın.


                              </span>
                            </span>
                        </span>
                
                </button>
              </nav>
              {/* End Tab Navs */}
        
       
            </div>

            {/* Tab Content */}
            <div className="lg:col-span-6 hidden lg:block ">
              <div className="relative items-center justify-center">
                <div className={`items-center justify-center ${activeTab === 1 ? 'block' : 'hidden'}`} id="tabs-with-card-1" role="tabpanel" aria-labelledby="tabs-with-card-item-1">
                    <div class="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden ">
                      <div class="md:flex">
                          <div class="md:flex-shrink-0">
                          <img className="shadow-xl  w-full object-cover md:h-full md:w-48" src="image/AdobeStock_258540015.webp"/>
                          </div>
                          <div class="p-8">
                          <p class="mt-2 text-gray-600 text-lg block">
                               Dijital varlıklar artık markaların vazgeçilmez bir parçası haline geldi. 
                               Biz, "İlham Veren Tasarımlar" felsefemizle amacımız markanızın özünü yakalamak 
                               ve dijital platformlarda güçlü bir şekilde temsil etmek.
                           </p>
                          </div>
                      </div>
                    </div>


                  

                </div>

                <div className={`items-center justify-center ${activeTab === 2 ? 'block' : 'hidden'}`} id="tabs-with-card-2"   role="tabpanel" aria-labelledby="tabs-with-card-item-2">
                            
                  <div class="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                        <div class="md:flex">
                            <div class="md:flex-shrink-0">
                            <img className="shadow-xl  w-full object-cover md:h-full md:w-48" src="image/AdobeStock_423247545.webp"/>

                            </div>
                            <div class="p-8">
                                <h2 class=" hidden mt-1 text-lg leading-tight font-medium text-black">Web Sitenizi Canlandırıyoruz</h2>
                                <p class="mt-2 text-gray-600 text-lg block">
                                Web sitenizin gücünü ortaya çıkarıyoruz - etkileşimli, dinamik ve canlı bir 
                                platforma dönüşmesi için. "Web Sitenizi Canlandırıyoruz" yaklaşımımızla, sıradan 
                                statik sayfalardan çok daha fazlasını sunuyoruz. İçerikleriniz, animasyonlar, multimedya öğeleri ve 
                                etkileşimli tasarımlarla canlanıyor. H
                                </p>
                            </div>
                        </div>
                      </div>
              

                </div>
                
                <div id="tabs-with-card-3" className={`items-center justify-center ${activeTab === 3 ? 'block' : 'hidden'}`} role="tabpanel" aria-labelledby="tabs-with-card-item-3">
                <div class="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                      <div class="md:flex">
                          <div class="md:flex-shrink-0">
                          <img className="shadow-xl  w-full object-cover md:h-full md:w-48" src="image/AdobeStock_452415507.webp"/>

                          </div>
                          <div class="p-8">
                              <h2 class="block mt-1 text-lg leading-tight font-medium text-black">Geleceğe Ulaşım</h2>
                              <p class="mt-2 text-gray-600 text-lg block">

                              Önceliğimiz yüksek performans, hızlı yüklenme süreleri, güvenlik ve SEO uyumluluğudur. 
                              Kullanıcı deneyimini her zaman ön planda tutarız, böylece kullanıcılarınız her öğeyi kolayca bulabilir 
                              ve etkileşime geçebilirler.

    </p>
                                <p class="mt-2 text-gray-600 text-lg block">

                                Bugünden itibaren geleceğe yönelik adımlarınızı atın ve dijital dünyada yerinizi sağlamlaştırın.
                              </p>
                          </div>
                      </div>
                    </div>
        
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
    <div className="relative isolate overflow-hidden bg-white px-6 pt-24 sm:pt-32 lg:overflow-visible lg:px-0">

      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Bizimle İletişime Geçin</p>
              <h1 className="mt-2 text-base font-bold tracking-tight text-gray-900 sm:text-2xl">Ad Astra Yazılım olarak, teknolojinin gücünü işletmenizin hizmetine sunmaktan gurur duyarız.</h1>
              <p className="mt-6 text-base leading-8 text-gray-700">
              Yazılım çözümlerimizle iş süreçlerinizi dönüştürmek ve dijital dönüşüm yolculuğunuzda size eşlik etmek için buradayız. İster özel yazılım geliştirmek, ister mevcut sistemlerinizi iyileştirmek olsun, her türlü ihtiyacınıza cevap verecek uzmanlığa sahibiz. İşletmenizin geleceğini birlikte şekillendirelim!
              </p>

              <div className=" mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold text-center mb-8">Bize Ulaşın</h2>
      <div className="max-w-md mx-auto space-y-4">
        <div className="flex items-center">
          <PhoneIcon className="h-4 w-4 text-blue-500 mr-2" />
          <p className="text-base text-gray-900  p-1"> Telefon:  <a href="tel:+902124011396">+90 212 401 13 96 </a>| </p>
          <p className="text-base text-gray-900">  <a href="tel:+905321549955">+90 532 154 99 55</a></p>

        </div>
        <div className="flex items-center">
          <EnvelopeIcon className="h-4 w-4 text-blue-500 mr-2" />
          <p className="text-base text-gray-900"> E-posta: <a href="mail:info@adastrai.com">info@adastrai.com</a></p>
        </div>
        <div className="flex items-center">
          <MapPinIcon className="h-6 w-6 text-blue-500 mr-2" />
          <p className="text-base text-gray-900"> Adres:  Esentepe, Yüzbaşı Kaya Aldogan Sk. No:4, 34394 Şişli/İstanbul</p>
        </div>
        <div className="flex items-center">
          <ClockIcon className="h-5 w-5 text-blue-500 mr-2" />
          <p className="text-base text-gray-900"> Çalışma Saatleri: Hafta içi 09:00 - 18:00</p>
        </div>
      </div>
    </div>

            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="https://lh5.googleusercontent.com/p/AF1QipM56JBO6_fJgEIiHHf2iYXhmy_zPwF574rntGNE"
            alt=""
          />
        </div>
       
      </div>
    </div>

    {reviews && (<ReviewItem reviews={reviews}/>)}
    </div>

  )
}
