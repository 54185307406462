
import { Disclosure, Menu,Popover, Transition } from '@headlessui/react'
import React, { useEffect, useState,Fragment } from "react";

import clsx from 'clsx'; 
import { LANGUAGES } from "../constants"; 
import { useTranslation } from 'react-i18next';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {FiGlobe} from 'react-icons/fi';
import {HiUser, HiUserAdd} from 'react-icons/hi';
import {IoLocationSharp} from 'react-icons/io5';
import { IoIosArrowForward } from 'react-icons/io'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon,CircleStackIcon, 
  SparklesIcon, CommandLineIcon,AcademicCapIcon, CpuChipIcon, NewspaperIcon, 
  PuzzlePieceIcon,UserGroupIcon, InformationCircleIcon, BookmarkIcon, FlagIcon,ArrowUpIcon,ArrowUpRightIcon,ChevronUpIcon,ChevronRightIcon} from '@heroicons/react/20/solid'
import {
    ArrowPathIcon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
  } from '@heroicons/react/24/outline'

  

const iconMapping = {
  ArrowPathIcon:<ArrowPathIcon />,
  ChartPieIcon:<ChartPieIcon />,
  CursorArrowRaysIcon:<CursorArrowRaysIcon />,
  FingerPrintIcon:<FingerPrintIcon />,
  SquaresPlusIcon:<SquaresPlusIcon />,
  Bars3Icon: <Bars3Icon />,
  BellIcon: <BellIcon />,
  XMarkIcon: <XMarkIcon />,
  FiGlobe: <FiGlobe />,
  HiUser: <HiUser />,
  HiUserAdd: <HiUserAdd />,
  IoLocationSharp: <IoLocationSharp />,
  IoIosArrowForward: <IoIosArrowForward />,
  ChevronDownIcon: <ChevronDownIcon />,
  PhoneIcon: <PhoneIcon />,
  PlayCircleIcon: <PlayCircleIcon />,
  CircleStackIcon: <CircleStackIcon />,
  SparklesIcon: <SparklesIcon />,
  CommandLineIcon: <CommandLineIcon />,
  AcademicCapIcon: <AcademicCapIcon />,
  CpuChipIcon: <CpuChipIcon />,
  NewspaperIcon: <NewspaperIcon />,
  PuzzlePieceIcon: <PuzzlePieceIcon />,
  UserGroupIcon: <UserGroupIcon />,
  InformationCircleIcon: <InformationCircleIcon />,
  BookmarkIcon: <BookmarkIcon />,
  FlagIcon: <FlagIcon />,
  ArrowUpIcon: <ArrowUpIcon />,
  ArrowUpRightIcon: <ArrowUpRightIcon />,
  
};


export default function FlyNavigation2() {

  const { i18n, t } = useTranslation();
  
  const navigationTranslated = t('navigation', { returnObjects: true });
  
	const [isScrolled, setIsScrolled] = useState(false);
  const pathname = window.location.pathname;
 

  const [openMenu, setOpenMenu] = useState(false);
  const [currentHash, setCurrentHash] = useState(window.location.hash);

  const handleMenuClick = () => {
    setOpenMenu(prevOpenMenu => !prevOpenMenu); // Menüyü aç/kapat
    if (!openMenu) {
      window.scrollTo(0, 0); // Eğer menü kapalıysa, sayfayı en üste kaydır
    } 
    const scrollContainer = document.getElementById('flyMenu');
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  };
 
	useEffect(() => { 

    const handleHashChange = () => {
      setCurrentHash(window.location.hash);
      console.log(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    const handleScroll = () => {
		const currentScrollPos = window.pageYOffset;
		const scrolled = currentScrollPos > 20;
		 
		setIsScrolled(scrolled);
	  };
  
	  window.addEventListener('scroll', handleScroll);
  
	  return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('hashchange', handleHashChange);
	  };
  
	}, []);
  
	const navbarClasses = clsx(
	  'h-14 sticky top-0 z-50 transition-colors duration-500  hover:bg-white hover:opacity-100 hover:shadow-lg',
	  {
		'opacity-95': isScrolled,
		'opacity-100': !isScrolled, 
		'bg-white': isScrolled, 
		'bg-transparent': !isScrolled, 
		'shadow-bottom': isScrolled
	  }
	);

  return (
    <>
 

      {typeof navigationTranslated !== "string" && navigationTranslated && navigationTranslated.map(menuItem => {
        menuItem.current = false;
        
        if (menuItem.href == currentHash) {
          //console.log("OK:"+ currentHash);
          menuItem.current = true;
         }
        if (menuItem.submenu) {
          menuItem.submenu.forEach(subMenuItem => {
            subMenuItem.current = false;
            //console.log(subMenuItem.href +"==" + currentHash);
           
            if (subMenuItem.href == currentHash) {
              //console.log("OK:"+ currentHash);
              menuItem.current = true;
              subMenuItem.current = true;
            }
          });
        }
      })}
 
  <header className=" sticky top-0  transition-colors duration-500 bg-[#f5f5f7] hover:opacity-100 hover:shadow-lg z-50 w-full  text-sm py-3 md:py-0 dark:bg-gray-800">
    <nav className="max-w-[85rem] w-full mx-auto px-4 md:px-6 lg:px-8" aria-label="Global">
      <div className="relative md:flex md:items-center md:justify-between">
        <div className="flex items-center justify-between">
          <a className="flex-none text-xl font-semibold dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#" aria-label="Brand">
            <img className="ps-8 h-12 w-auto" src="/image/adastra@4x.png" alt="ad astra"/>
          </a>
             
          <div className="md:hidden">
            <button type="button" className="hs-collapse-toggle flex justify-center items-center w-9 h-9 text-sm font-semibold rounded-lg border border-gray-200 text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
              <svg className="hs-collapse-open:hidden flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="3" x2="21" y1="6" y2="6"/><line x1="3" x2="21" y1="12" y2="12"/><line x1="3" x2="21" y1="18" y2="18"/></svg>
              <svg className="hs-collapse-open:block hidden flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>

        <div id="navbar-collapse-with-animation" className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block">
          <div className="overflow-hidden overflow-y-auto max-h-[75vh] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
            <div className="flex flex-col gap-x-0 mt-5 divide-y  divide-dashed divide-gray-200 md:flex-row md:items-center md:justify-end md:gap-x-7 md:mt-0 md:ps-7 md:divide-y-0 md:divide-solid dark:divide-gray-700">
            {
                    (typeof navigationTranslated !== "string" && navigationTranslated) && navigationTranslated.map((item, index) => (
                      <React.Fragment key={index}>
                      {item.href === "#" ? (
                        <>
                           <div className="hs-dropdown [--strategy:static] [--auto-close:true] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:click] lg:[--trigger:hover] py-2 md:py-2 ">
                            <button type="button" 
                             className={clsx(
                              item.current ? 'text-blue-600' : 'text-gray-500 hover:text-gray-600',
                              'flex items-center w-full   font-medium text-xl dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600'
                          )}
                            >
                            {item.name} 
                            <ChevronDownIcon className="hs-dropdown-open:opacity-0 h-5 w-5 text-xl text-gray-400 group-hover:text-gray-900" aria-hidden="true" />
                            </button>
                          
                            

                            <div className="hs-dropdown-menu transition-[opacity,margin] lg:w-4/12 duration-[0.1ms]  md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0  hidden z-10   bg-white md:shadow-2xl rounded-lg py-2 md:p-4 dark:bg-gray-800 dark:divide-gray-700  ">
                            <div className="md:grid md:grid-cols lg:grid-cols gap-4 ">
                              <div className="flex flex-col mx-1 md:mx-0">
                              {item.submenu.map((submenuItem, index) => (
                                <div key={submenuItem.name} 
                                    // SubMenu Item stilini belirleyen sınıflar
                                        className={clsx(
                                          submenuItem.current ? 'border-b-0 bg-sky-100' : 'hover:text-opacity-100 text-gray-900 text-opacity-80',
                                          'group relative bg-white flex gap-x-6 rounded-lg p-4 hover:bg-sky-100'
                                      )}  
                                    >
                                        {/* Menü öğesinin ikonu */}
                                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            {iconMapping[submenuItem.icon] && React.cloneElement(iconMapping[submenuItem.icon], {
                                                className: "h-6 w-6 text-gray-600 group-hover:text-indigo-600",
                                                'aria-hidden': "true"
                                            })}
                                        </div>
                                        {/* Menü öğesinin ismi ve açıklaması */}
                                        <div>
                                            <a href={submenuItem.href} className="text-gray-700 text-base hover:text-gray">
                                                {submenuItem.name}
                                                <span className="absolute inset-0" />
                                            </a>
                                            <p className="mt-1 text-gray-600 text-sm">{submenuItem.description}</p>
                                        </div>
                                    </div>
                                ))}
 
 
                              </div>
 
                            </div>
                          </div>


                          </div>
                        </>
                      ) : (
<>
                      <div className="py-2 md:py-2 ">

                          <a 
                          className={clsx(
                            item.current ? 'text-blue-600' : 'text-gray-500 hover:text-gray-600',
                            'transition-all font-medium text-xl dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600'
                          )}
                          href={`${item.href}`}
                          aria-current="page">
                            {item.name}
                          </a>
                    </div>
                  
                          {typeof item.submenu !== "string" && item.submenu && (
                            <ChevronDownIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-900" aria-hidden="true" />
                          )}
                        </>
                      )}
                    </React.Fragment>
                       
                  
                    ))}     

               
              <div className="pt-3 md:pt-0">
                <a className="relative  py-2.5 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" 
                href="tel:+905321549955">
                   <PhoneIcon className="h-5 w-5 text-gray-200 group-hover:text-gray-50" aria-hidden="true" />
                   Ara
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
    </>
  );
}






