import React, { useRef, useState,useEffect } from 'react';
import Slider from 'react-slick';
import ReactFlagsSelect from 'react-flags-select'; 
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import About from './About';
import { PhoneIcon,EnvelopeIcon  } from '@heroicons/react/24/outline'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
 

// import required modules
import { Pagination,EffectCards,EffectCreative } from 'swiper/modules';
 

function HeroSection() {
 
 
	return (
    <>
    <button type="button" class="hs-collapse-toggle  py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" id="hs-basic-collapse" data-hs-collapse="#hs-basic-collapse-heading">
      <svg class="hs-collapse-open:rotate-180  w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
    </button>
    <div id="hs-basic-collapse-heading" class="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-collapse">
      <div data-aos="fade-down"  data-aos-delay="1500" data-aos-duration="1000" className="relative inline-flex items-center  bg-gray-900 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10" id="home">
  
        <div className="flex flex-col lg:flex-row place-content-center pb-4 py-3 pt-5  ml-10  content-center"  >
        
        <div className=" basis-8/12 ">
          <h1 className=" text-3xl font-medium tracking-tight text-left text-[#e30000]">Yapay Zeka. 
          <span className="text-3xl  font-medium tracking-tight text-left text-gray-400 pl-1 ml-1">Yeni bir çağın kapıları açılıyor.
          Bu devrimi kaçırmayın!
        </span>
          </h1>
        </div>	  
        <div className='inline-flex items-center rounded-md bg-[#e30000] px-3 py-3  text-gray-200 ring-1 ring-inset ring-indigo-700/10 basis-4/12 text-base  tracking-tight'> 

        Yapay zekanın önemli senaryolarını ve süreçlerini keşfedin. 
        İşletmenizi daha ileriye taşımak için yapay zekayı nasıl kullanabileceğinizi öğrenmek için bize ulaşın.

        </div>

        </div>
      </div>
    </div>
    
     <Swiper 
         loop={true}
        effect={'creative'}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [-10000, 0, -500],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }} 
        grabCursor={true} 
        slidesPerView={1} 
        spaceBetween={30} 
        pagination={{
          clickable: true,
        }}
        modules={[Pagination,EffectCreative]}
   
        className="mySwiper"
      >
    
 <SwiperSlide> 
    <div  data-aos="fade-right" data-aos-duration="500" className="    relative" id="home">
  
        <div className="max-w-7xl pt-10  mx-auto px-6 md:px-12 xl:px-6">
        
        <div className="lg:w-4/5 mx-auto  relative flex flex-wrap">
        <img alt="LuxeDrive" className="absolute right-0 h-12 w-12 text-gray-50 grayscale opacity-0"   data-aos="fade-right" data-aos-duration="500"  src="/image/new-label-icon.svg"/>
        
        <img alt="LuxeDrive" className=" hidden lg:inline lg:w-1/2 w-full  lg:h-auto h-64 object-cover object-center rounded" src="/image/macbookproje@2x.webp"/>
      <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
        <h2 className="text-xs title-font text-gray-500 tracking-widest">Yapay Zeka Destekli</h2> 
        <h2  className="text-blue-900  text-left dark:text-white font-bold text-2xl md:text-3xl xl:text-4xl">
        LuxeDrive </h2>
        <div className="flex mb-4 text-xl font-bold">
        VIP transfer hizmetleri sunan işletmeler için özel olarak tasarlanmış nihai web rezervasyon uygulaması LuxeDrive ile tanışın.
          </div>
        <p className="leading-relaxed text-xl">
        
Rota yönetimi, dinamik fiyatlandırma, rezervasyon yetenekleri ve kampanya entegrasyonu gibi modüler özellikleri ile LuxeDrive tüm sürecinizi basitleştirir ve otomatikleştirir.

Artık karmaşıklıkların VIP transfer hizmetinizi engellemesine izin vermeyin. LuxeDrive'ı seçin ve sorunsuz operasyonlar ve memnun müşteriler ile işinizi yükseltin. 🚗💨
          
          </p>
        
        
        
      </div>
    </div>
      
    </div>
</div>
                                    
 
  
    </SwiperSlide>
    <SwiperSlide> 
        <div  data-aos="fade-right" data-aos-duration="500" className="relative   h-max" id="home">
      
            <div className="max-w-7xl pt-24 pb-12  mx-auto px-6 md:px-12 xl:px-6">

                <div className="relative  ml-auto">
                    <div className="lg:w-2/3 text-center mx-auto">
                    <h2  className="text-blue-900  text-left dark:text-white font-bold text-xl md:text-3xl xl:text-2xl">
                        Ad Astra Yazılım
                        </h2>

                        <h1 className="text-gray-900 text-left dark:text-white font-bold text-4xl md:text-5xl xl:text-6xl">
                        Bizimle birlikte yeni bir ilerleme ve yenilik çağını keşfedin:</h1>
                        
                        <p className="mt-4 mb-5 text-gray-700 text-left text-xl dark:text-gray-300">
                    
                          </p>
                          <p className="mt-4 mb-5 text-gray-700 text-left text-xl dark:text-gray-300">
                            
                            Şirketimiz, Microsoft'un global destek programı olan <span className='font-semibold text-purple-900'> Microsoft for Startups Founders Hub'a</span> kabul edildi. 
    Sektör liderinden aldığımız desteğin ve güvenin farkındayız ve size garanti ediyoruz. 
    Yapay zeka çağını kaçırmayın, birlikte işletmenizi inovasyonun ön saflarına taşıyalım.
                          </p>
                          <div className='flex'>
                            <div>
                      <a className=" mx-2  py-2 px-4 inline-flex items-center gap-x-2 text-base   rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" 
                                  href="tel:+902124011396">
                                    <PhoneIcon className="h-5 w-5 text-gray-200 group-hover:text-gray-50" aria-hidden="true" />
                                    Ara <span className="hidden lg:inline">0212 401 13 96</span>
                                  </a>
                    </div>
                    <div>
                    <a className=" ml-2 mx-2  py-2 px-4  inline-flex items-center gap-x-2 text-base   rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" 
                    href="tel:+902124011396">
                      <EnvelopeIcon className="h-5 w-5 text-gray-200 group-hover:text-gray-50" aria-hidden="true" />
                      info@adastrai.com
                    </a>
                    </div>
                    </div>
                    </div>
                      
              
                    
                </div>
            </div>
        </div>
    </SwiperSlide>
    
</Swiper>
<div className="bg-gray-200 mt-1 grayscale">
    <div className="container m-auto justify-center  space-y-8 md:px-12 lg:px-56">
       
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6">
   
            <div className="p-4">
              <img src="/image/open-ai.svg" className="h-8 sm:h-10 w-auto lg:h-12 opacity-50" alt="" />
                
            </div>
            <div className="p-4">
              <img src="/image/microsoftFounder.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" /> 
                
            </div>
            <div className="p-4">
              
              <img src="/image/microsoftAzure.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" /> 
                
            </div>
            <div className="p-4  ">
              <img src="/image/microsoftSQL.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" /> 
                
            </div>
            <div className="p-4">
             <img src="/image/Python.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" /> 
            
            </div>
            <div className="p-4">
              <img src="/image/Microsoft_.NET_logo.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" /> 
           
                
            </div>
    
        </div>
    </div>
</div>


    <div id="features" className="bg-sky-900  dark:bg-gray-50   pb-6">
    <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6   ">
      <div className="md:w-2/3 lg:w-1/2  pt-4 relative">
 
        
        <h2 className="my-2 text-xl  dark:text-gray-700 text-gray-100 md:text-2 xl">
        Hizmetlerimizin tamamında Yapay zekanın gücü ile nihai çözümler üretiyoruz. 
          Microsoft ve OpenAI tarafından desteklenen benzersiz yapay zeka araçlarımızla, insan zekasını güçlendirmek 
          ve dönüştürücü deneyimler sunmak için yapay zekanın potansiyelinden zahmetsizce yararlanabilirsiniz. 
          
        </h2>
        
      </div>
      <div
        className="mt-8 grid divide-x divide-y divide-gray-100 dark:divide-gray-700 overflow-hidden rounded-3xl border border-gray-100 text-gray-600 dark:border-gray-700 sm:grid-cols-2 lg:grid-cols-4 lg:divide-y-0 xl:grid-cols-4"
      >
        <div className="group relative   bg-gradient-to-r from-red-100 to-blue-50    transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div className="flex z-41 absolute bottom-2 right-0 h-16 w-16  items-center justify-between group-hover:text-secondary">
            
            <ArrowRightIcon className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100" aria-hidden="true" />               
          </div>
          <a   href="#/yapay-zeka-danismanlik" className="relative">
            <img
              src="/image/home-1.webp"
              className="drop-shadow-md opacity-90 group-hover:opacity-100 transition "
  
       
              alt="Yapay Zeka Çözümleri "
            />

            <div className="space-y-2 p-8">
              <h5
                className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
              >
               Yapay Zeka Çözümleri 
              </h5>
              <p className="text-gray-600 dark:text-gray-300">
              İşinizin potansiyelini artırmak için yapay zeka tekniklerini öğrenin ve uygulayın.
                            </p>
            </div>
 
          </a>
        </div>
        <div className="group relative  bg-gradient-to-r from-blue-50 to-orange-50   transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div className="flex z-41 absolute bottom-0 right-0 h-16 w-16  items-center justify-between group-hover:text-secondary">
            
            <ArrowRightIcon className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100" aria-hidden="true" />               
          </div>
          <a  href="#/egitim-atolye" className="relative">
          <img
              src="/image/home-2.webp"
              className="drop-shadow-md opacity-90 group-hover:opacity-100 transition "
  
              alt="AI Eğitimleri"
            />

            <div className="space-y-2  p-8">
              <h5
                className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
              >
            AI Eğitimleri
               </h5>
              <p className="text-gray-600 dark:text-gray-300">
              AI konusunda uzmanlaşmak için alanında lider eğitmenlerden pratik bilgiler alın.
                            </p>
            </div>
 
          </a>
        </div>
        <div className="group relative  bg-gradient-to-r from-orange-50 to-purple-50   transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div className="flex z-41 absolute bottom-0 right-0 h-16 w-16  items-center justify-between group-hover:text-secondary">
            
            <ArrowRightIcon className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100" aria-hidden="true" />               
          </div>
          <a  href="#/yazilim-gelistirme" className="relative">
 
          <img
              src="/image/home-3.webp"
              className="drop-shadow-md opacity-90 group-hover:opacity-100 transition "
  
              alt="Yazılım Hizmetleri"
            />
 
            <div className="space-y-2 p-8">
              <h5
                className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
              >
                Yazılım Hizmetleri
              </h5>
              <p className="text-gray-600 dark:text-gray-300">
              Projelerinizi gerçekleştirmek için 25 yıllık tecrübemiz ve kalite standartlarımız ile size destek oluyoruz. 
                            </p>
            </div>
           
          </a>
        </div>
        <div className="group relative  bg-gradient-to-r from-sky-50 to-purple-50  transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div className="flex z-41 absolute bottom-0 right-0 h-16 w-16  items-center justify-between group-hover:text-secondary">
            
            <ArrowRightIcon className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100" aria-hidden="true" />               
          </div>
          <a  href="#/web-site-gelistirme" className="relative">

          <img
              src="/image/home-4.webp"
              className="drop-shadow-md opacity-90 group-hover:opacity-100 transition "
  
              alt="Web Tasarımı"
            />
 
            <div className="space-y-2  p-8">
              <h5
                className="text-xl font-semibold text-gray-700 dark:text-white transition group-hover:text-secondary"
              >
               Web Tasarımı
              </h5>
              <p className="text-gray-600 dark:text-gray-300">
              İşletmenizin dijital kimliğini oluşturmak için size özel ve etkili web siteleri tasarlıyoruz.
              </p>
            </div>
 
          </a>
          
        </div>
        
      </div>
      
    </div>
    </div>

		
</>
	)
}

export default HeroSection