import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const getCurrentHost =  process.env.REACT_APP_API_URL;
  
i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_DEBUG === 'true',
    fallbackLng: "tr",
    lng: "tr",
    interpolation: {
      escapeValue: false, 
      formatSeparator: ','
    },
    react: {
        wait: false,
        useSuspense: true //   <---- this will do the magic
      
    },
    backend: {
      loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
    },
  });

export default i18n;