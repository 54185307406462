import {useState} from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

 
// import required modules
import { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper/modules';

function ReviewItem({reviews}) {
    const hueAngles = [0, 15, 30, 60, 90, 180];
    const randomHueAngle = hueAngles[Math.floor(Math.random() * hueAngles.length)];
    const hueClass = `hue-rotate-${randomHueAngle}`;
  
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="relative p-4 ' + className + '"><span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">' + (index + 1) + '</span></div>';
        },
    };
    

	return (
		 

        
<div     key={2212} className="relative overflow-hidden mt-20  bg-gray-200 bg-opacity-100" >
<img 
      className={`absolute -translate-x-1/2 ${hueClass} skew-x-3 scale-100 opacity-20`} 
      src="https://preview.cruip.com/talent/images/hero-illustration.svg" 
      width="2120" 
      height="931"
    />
  <div key={32323} className="relative max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

    <div key={111} className=" ">
    <Swiper style={{ 
 
  "--swiper-pagination-bullet-size": "16px;",
  "--swiper-pagination-bullet-width": "16px",
  "--swiper-pagination-bottom": "0px",
  "--swiper-pagination-bullet-height": "16px",
  "--swiper-pagination-bullet-border-radius": "50%",
  "--swiper-pagination-color": "#FFBA08",
  "--swiper-pagination-color": "#FFBA08",
  "--swiper-pagination-bullet-inactive-color": "#999999",
  "--swiper-pagination-bullet-inactive-opacity": "0.9",
  "--swiper-pagination-bullet-size": "32px",
  "--swiper-pagination-bullet-horizontal-gap": "10px",
  "--swiper-pagination-bullet-padding-left": "10px"
}}
            slidesPerView={1}
            centeredSlides={false}
            slidesPerGroupSkip={1}
            grabCursor={true}
            keyboard={{
                enabled: true,
            }}
            breakpoints={{
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
            }}
            scrollbar={true}
            speed={600}
            parallax={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Keyboard, Scrollbar, Navigation, Pagination]}
            className="mySwiper"
      >
      
    {reviews && reviews.map((review,index) => 
     <SwiperSlide> 
                <div key={index} className="relative flex h-auto"  data-aos="fade-in"
     
     data-aos-easing="ease-out-cubic"
      data-aos-delay={`${50 + 200 * index}`}>
                <div className="flex flex-col bg-white rounded-xl dark:bg-slate-900 after:content-[''] after:bg-gray-400 after:absolute after:w-2 after:top-0 after:left-0 after:bottom-0 after:rounded-tl-lg after:rounded-bl-lg">
                <div className="flex-auto p-4 md:p-6">
                    <p className="text-2xl italic md:text-2xl text-blue-800 font-review tracking-wide">
                    "{review.text}"</p>
                </div>

                <div className="p-4 bg-gray-100 rounded-b-xl md:px-7 dark:bg-slate-700">
                    <div className="flex items-center">
            

                    <div className="grow ml-3">
                        <p className="text-sm sm:text-base font-semibold text-gray-800 dark:text-gray-200">
                        {review.name}
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                        {review.title} | {review.company}

            </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </SwiperSlide>
            )}
    </Swiper>  
    
    </div>
    
    <div className="text-xs text-sky-900 mt-10 z-10 flex  ">
        <InformationCircleIcon  className="h-4 w-4 text-red-50 shadow-xl mr-2" aria-hidden="true" />
        <span className="w-full">
            Tüm geri bildirimler, hizmetlerimizden faydalanan <span className='text-gray-900 font-bold shadow-sm'>gerçek müşterilerimiz</span> tarafından bize iletilmiştir ve müşterilerimizin onayı alınarak paylaşılmıştır. Saygınlığımızı ve şeffaflığımızı koruma adına, sahte ya da yanıltıcı içerik üretme pratiğimiz yoktur. Kullanıcılarımızın deneyimlerini, diğer potansiyel müşterilerimizle paylaşma amacıyla bu yorumları sitemizde yer veriyoruz. Bu yorumlar, müşterilerimizin gerçek düşüncelerini, deneyimlerini ve memnuniyetini yansıtmaktadır. Sizlere daha iyi hizmet sunabilmek için bu geri dönüşleri değerli buluyor ve ciddiye alıyoruz.
        </span>
    </div>


        <div className="absolute bottom-20 right-1 transform    hue-rotate-180	translate-x-32 opacity-80" aria-hidden="true">
          <svg className="w-20 h-auto sm:w-52" width="650" height="336" viewBox="0 0 1115 636" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.990203 279.321C-1.11035 287.334 3.68307 295.534 11.6966 297.634L142.285 331.865C150.298 333.965 158.497 329.172 160.598 321.158C162.699 313.145 157.905 304.946 149.892 302.845L33.8132 272.418L64.2403 156.339C66.3409 148.326 61.5475 140.127 53.5339 138.026C45.5204 135.926 37.3213 140.719 35.2207 148.733L0.990203 279.321ZM424.31 252.289C431.581 256.26 440.694 253.585 444.664 246.314C448.635 239.044 445.961 229.931 438.69 225.96L424.31 252.289ZM23.0706 296.074C72.7581 267.025 123.056 230.059 187.043 212.864C249.583 196.057 325.63 198.393 424.31 252.289L438.69 225.96C333.77 168.656 249.817 164.929 179.257 183.892C110.144 202.465 54.2419 243.099 7.92943 270.175L23.0706 296.074Z" fill="currentColor" className="fill-orange-500"/>
            <path d="M451.609 382.417C446.219 388.708 446.95 398.178 453.241 403.567L555.763 491.398C562.054 496.788 571.524 496.057 576.913 489.766C582.303 483.474 581.572 474.005 575.281 468.615L484.15 390.544L562.222 299.413C567.612 293.122 566.881 283.652 560.59 278.263C554.299 272.873 544.829 273.604 539.44 279.895L451.609 382.417ZM837.202 559.655C841.706 566.608 850.994 568.593 857.947 564.09C864.9 559.586 866.885 550.298 862.381 543.345L837.202 559.655ZM464.154 407.131C508.387 403.718 570.802 395.25 638.136 410.928C704.591 426.401 776.318 465.66 837.202 559.655L862.381 543.345C797.144 442.631 718.724 398.89 644.939 381.709C572.033 364.734 504.114 373.958 461.846 377.22L464.154 407.131Z" fill="currentColor" className="fill-cyan-500"/>
            <path d="M447.448 0.194357C439.203 -0.605554 431.87 5.43034 431.07 13.6759L418.035 148.045C417.235 156.291 423.271 163.623 431.516 164.423C439.762 165.223 447.095 159.187 447.895 150.942L459.482 31.5025L578.921 43.0895C587.166 43.8894 594.499 37.8535 595.299 29.6079C596.099 21.3624 590.063 14.0296 581.818 13.2297L447.448 0.194357ZM1086.03 431.727C1089.68 439.166 1098.66 442.239 1106.1 438.593C1113.54 434.946 1116.62 425.96 1112.97 418.521L1086.03 431.727ZM434.419 24.6572C449.463 42.934 474.586 81.0463 521.375 116.908C568.556 153.07 637.546 187.063 742.018 200.993L745.982 171.256C646.454 157.985 582.444 125.917 539.625 93.0974C496.414 59.978 474.537 26.1903 457.581 5.59138L434.419 24.6572ZM742.018 200.993C939.862 227.372 1054.15 366.703 1086.03 431.727L1112.97 418.521C1077.85 346.879 956.138 199.277 745.982 171.256L742.018 200.993Z" fill="currentColor" className="fill-white"/>
          </svg>
        </div>
    </div>
</div>
    
    )
}

export default ReviewItem