import {useState, useEffect} from 'react'
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import { CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline'
import Reviews from 'api/reviewAIConsulting.json'
import ReviewItem from 'components/ui/ReviewItem'




export default function AIConsulting() {
  const [reviews, setReviews] = useState([])

	useEffect(() => {
		setReviews(Reviews)
	}, [])

	

  return (
<div className="relative overflow-hidden " >
<img 
        className={`absolute w-full  scale-[1.3]`} 
        src="https://preview.cruip.com/job-board/images/hero-illustration.svg" 
        width="2120" 
        height="931"
        alt="adastrai.com"
      />
      
<div className="container relative isolate  px-6 pt-32   lg:overflow-visible lg:px-0">
  
       <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" data-aos="fade-left" data-aos-duration="500" >
            Yapay Zeka ile İşinizde Fark Yaratın.</h2>
            <p className="mt-4 text-lg leading-8 text-gray-600" data-aos="fade-right" data-aos-duration="500" >         

              İşiniz için özelleştirilmiş yapay zeka çözümleri sunarak, teknolojinin gücünü sizinle paylaşıyoruz. Adastrai.com olarak, dijital dönüşümün öncüsü olmanız için sizi destekliyoruz. Sektöre özgü bilgi ve deneyimimizle, yapay zeka projelerinizde başarıyı garantiliyoruz. Hayallerinizdeki geleceği birlikte hayata geçirelim.



            </p>
             
          </div>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2" data-aos="zoom-in-up"  data-aos-duration="500">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <CalendarDaysIcon className="h-6 w-6  text-indigo-600" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-gray-900">Yapay Zeka Projelerinizde Uzman Destek</dt>
              <dd className="mt-2 leading-7 text-gray-600">
                Yapay zeka projelerinizde, Adastrai.com’un deneyimli ve yetkin ekibinden destek alabilirsiniz. Sizin için en uygun yapay zeka stratejilerini belirliyor, projenizin tasarım, geliştirme ve test aşamalarında size rehberlik ediyoruz. Yapay zeka projelerinizde kalite, verimlilik ve güvenlik standartlarını sağlıyoruz.

              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <HandRaisedIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold  text-gray-900">
              Yapay Zeka Eğitimleri ve Atölye Çalışmaları

</dt>
              <dd className="mt-2 leading-7 text-gray-600">
                  Yapay zeka konusunda bilgi ve becerilerinizi geliştirmek için Adastrai.com’un sunduğu eğitim ve atölye çalışmalarına katılabilirsiniz. Yapay zeka alanında uzman eğitmenlerden teorik ve pratik bilgiler alabilir, sektördeki en güncel teknoloji ve uygulamaları öğrenebilirsiniz. Yapay zeka eğitimleri ve atölye çalışmaları, işinizde fark yaratmanıza yardımcı olacaktır.
</dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-gray-100 to-white opacity-30"

          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

 
      <div className="  mx-auto grid max-w-2xl grid-cols-1 mt-16  gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg" data-aos="zoom-in" >
              <p className="text-base font-semibold leading-7 text-indigo-600">Ad Astra'nın Farkı: </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Sektörel Uzmanlık</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Yapay zeka teknolojileri, her sektör için farklı uygulama alanları sunar. Adastrai.com, bu sektörel özellikleri iyi bilerek, her müşteri için özel ve etkili çözümler geliştirir.              </p>
            </div>
          </div>
        </div>
        <div className="-ml-1 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img data-aos="fade-in"
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="/image/adastrai_1.webp"
            alt=""
          />
        </div>
        <div className=" lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
        </p>
              <ul  className="mt-8 space-y-8 text-gray-600"  data-aos="fade-in">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon className="mt-1 h-6 w-6 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Gizlilik ve Güvenlik. </strong> 
                    Müşterilerimizin verilerinin gizliliği ve güvenliği bizim için çok önemlidir. Yapay zeka projelerinizde, veri gizliliği ve siber güvenliği en üst düzeyde sağlıyoruz.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon className="mt-1 h-6 w-6 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Yenilikçilik ve Gelişim. </strong> 
                    Adastrai.com olarak, sektördeki en güncel yapay zeka trendlerini ve yenilikleri izliyoruz. Müşterilerimize her zaman daha iyi ve daha etkili çözümler sunmak için eğitimlerimize ve araştırmalarımıza devam ediyoruz.


                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-6 w-6 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Müşteri Başarısı = Ad Astra Başarısı. </strong> 
                      Yapay zeka projelerinizdeki memnuniyetiniz, Adastrai.com’un memnuniyetidir. Sizi sadece bir müşteri değil, uzun vadeli bir iş ortağı olarak görüyoruz.

                  </span>
                </li>
              </ul>
              <h4 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Bize Ulaşın.</h4>

              <p className="mt-1">
             
              Ad Astra Yapay Zeka Danışmanlık Hizmetleri, sizinle iş dünyasında yapay zeka’nın sunduğu sınırsız potansiyeli keşfetmek için hazır. Yenilikçi çözümlerimiz, deneyimli ekibimiz ve sektördeki güçlü pozisyonumuzla, işinizi geleceğe taşıyacak adımları birlikte atalım. Şimdi bize ulaşın ve yapay zeka’nın işiniz için neler yapabileceğini keşfedin! Ad Astra ile daha parlak bir geleceğe doğru adım atın.
                            </p>
 
         
            </div>
          </div>
        </div>
      </div>





    </div>
    {reviews && (<ReviewItem reviews={reviews}/>)}
  
    </div>
  )
}
